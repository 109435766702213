<template>
  <button
    class="button"
    :disabled="disabled"
    :class="{
      small: props.small,
      inactive: props.inactive,
      red: props.danger,
      transparent: props.transparent,
      empty: props.empty,
      grey: props.grey,
      animation: props.animation,
    }"
  >
    <slot></slot>
  </button>
</template>

<script setup>
const props = defineProps({
  small: {
    type: Boolean,
    required: false,
  },
  inactive: {
    type: Boolean,
    default: false,
    required: false,
  },
  danger: {
    type: Boolean,
    required: false,
  },
  transparent: {
    type: Boolean,
    required: false,
  },
  grey: {
    type: Boolean,
    required: false,
  },
  empty: {
    type: Boolean,
    required: false,
  },
  animation: {
    type: Boolean,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
})
</script>

<style lang="scss" scoped>
.button {
  border-radius: 32px;
  padding: 14px 44px;
  border: none;
  gap: 10px;
  background: linear-gradient(to right, #714fff, #ec4587, #e87331);
  cursor: pointer;
  // @include flex-row-items-center;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    filter: brightness(50%);
  }

  &.small {
    @extend .button;
    font-weight: 400;
    font-size: 14px;
    padding: 7px 13px;
  }

  &.inactive {
    @extend .button;
    background: transparent;
    border: 1px solid blueviolet;
  }

  &.red {
    @extend .button;
    background: #e5463b;
  }

  &.transparent {
    @extend .button;
    background: transparent;
    color: #151515;
    border: 1px solid blueviolet;
  }
  &.empty {
    @extend .button;
    background: transparent;
  }

  &.grey {
    @extend .button;
    background: #5c5d5f !important;
    border: 1px solid #5c5d5f !important;
    cursor: default;
  }

  &.animation {
    @extend .button;
    z-index: 1;
    will-change: transform;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 100%;
      z-index: -1;
      background: linear-gradient(to right, #714fff, #ec4587, #e87331);
      background-size: 100%;
      border-radius: 32px;
      animation: pulsing 1.5s infinite cubic-bezier(0.5, 0, 0, 0.5);
      filter: blur(0px);
    }
  }

  @keyframes pulsing {
    to {
      filter: blur(15px);
    }
  }
}

@media (max-width: 750px) {
  .button {
    padding: 8px 19px;
    font-size: 14px;
  }

  .animation {
    &:before {
      animation: none !important;
    }
  }
}
</style>
